import React from 'react';
import {Button, TextField, InputLabel, Select, MenuItem, FormControl, Hidden, Grid, Box, Typography, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { getProfile } from "../../lib/api/profile"
import { defaultValues, profileFormInfo } from '../../common/profile';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs';
import { ErrorModal } from '../../components/ErrorModal';
import Cookies from "js-cookie";

export const Profile = () =>{
  const { control, setValue} = useForm({defaultValues});
  const navigate = useNavigate();
  const [kindState, setKind] = useState();
  const [isDisableEdit, setIsEdit] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [isAccountDisable, setIsAccountDisable] = useState(false);

  const handleOpenErrorModal = () =>{
    setErrorModalOpen(!errorModalOpen);
  }

  const handleGetLead = async () => {
    const res = await getProfile();

    if (res.status === 200) {
      const record = res.data.record
      setValue('firstname', !record.firstname || record.firstname == '未設定' ? '' :record.firstname);
      setValue('lastname', !record.lastname || record.lastname == '未設定' ? '' :record.lastname);
      setValue('state', !record.state ? '' :record.state);
      setValue('email', !record.email ? '' :record.email);
      setValue('company', !record.company ? '' :record.company);
      setValue('companynameFurigana', !record.companynameFurigana ? '' : record.companynameFurigana);
      setValue('mobilephone', !record.phone ? '' :record.phone);
      setValue('city', !record.city ? '' :record.city);
      setValue('street', !record.street ? '' :record.street);
      setValue('postalcode', !record.postalcode ? '' :record.postalcode);
      setValue('kind', !record.kind ? '' :record.kind);
      setValue('industry', !record.kind ? '' :record.kind);
      setValue('businessPostalcode', !record.businessPostalcode ? '' :record.businessPostalcode);
      setValue('businessState', !record.businessState ? '' :record.businessState);
      setValue('businessCity', !record.businessCity ? '' :record.businessCity);
      setValue('businessStreet', !record.businessStreet ? '' :record.businessStreet);
      setValue('businessOther', !record.businessOther ? '' :record.businessOther);
      setValue('corporatenumberAccount', !record.corporatenumberAccount ? '' :record.corporatenumberAccount);
      setValue('position', !record.position ? '' :record.position);
      setValue('firstnamekana', !record.firstnamekana ? '' :record.firstnamekana);
      setValue('lastnamekana', !record.lastnamekana ? '' :record.lastnamekana);
      setValue('raPostalcode', !record.raPostalcode ? '' :record.raPostalcode);
      setValue('raState', !record.raState ? '' :record.raState);
      setValue('raCity', !record.raCity ? '' :record.raCity);
      setValue('raStreet', !record.raStreet ? '' :record.raStreet);
      setValue('raOther', !record.raOther ? '' :record.raOther);
      setValue('mobilephone', !record.mobilephone ? '' :record.mobilephone);
      setValue('seibetsu', !record.seibetsu ? '' :record.seibetsu);
      setValue('birthdate', !record.birthdate ? dayjs('') : dayjs(record.birthdate));
      setValue('bankname', !record.bankname ? '' :record.bankname);
      setValue('banknameSearch', !record.bankname ? '' :record.bankname);
      setValue('branchname', !record.branchname ? '' :record.branchname);
      setValue('accounttype', !record.accounttype ? '' :record.accounttype);
      setValue('accounttypeOther', !record.accounttypeOther ? '' :record.accounttypeOther);
      setValue('accountnumber', !record.accountnumber ? '' :record.accountnumber);
      setValue('accountholder', !record.accountholder ? '' :record.accountholder);
      setValue('accountnameKana', !record.accountnameKana ? '' :record.accountnameKana);
      setValue('emergencycontactName', !record.emergencycontactName ? '' :record.emergencycontactName);
      setValue('emergencycontactFurigana', !record.emergencycontactFurigana ? '' :record.emergencycontactFurigana);
      setValue('emergencycontactRelationship', !record.emergencycontactRelationship ? '' :record.emergencycontactRelationship);
      setValue('emergencycontactPhoneno', !record.emergencycontactPhoneno ? '' :record.emergencycontactPhoneno);
      setValue('identification', !record.identification ? '' :record.identification);
      setValue('industry', !record.industry ? '' : record.industry);
      setValue('tashariyoureki', !record.tashariyoureki ? '' : record.tashariyoureki);
      setValue('othercompaniesusedinthepast', !record.othercompaniesusedinthepast ? '' : record.othercompaniesusedinthepast);
      setKind(!record.kind ? '' :record.kind);
      const lastStatus = record.lastOppStatus;
      if(record.portaluserStatus == '利用停止') setIsAccountDisable(true); 
      if(!lastStatus || lastStatus == '申請中' || lastStatus == '見込み' || lastStatus == 'NG処理待ち' || lastStatus == 'NG' || lastStatus == 'キャンセル' || lastStatus == 'キャンセル処理待ち' || lastStatus == '実行済み') setIsEdit(false);
      else setIsEdit(true);
      if(record.identification == '確認中') setIsEdit(true)
      setIsLoad(true);
    }
  }

  const {id} = useParams();

  useEffect(() => {
    handleGetLead();
  }, [])

  const onClickEdit = () =>{
    if(!id) return;
    if(isDisableEdit){
      setErrorMessage(<Typography>本人確認中、または契約手続き中のお客様は会員情報を編集できません。</Typography>);
      handleOpenErrorModal();
      return;
    }
    navigate("/myprofile/edit/" + Cookies.get('currentUserId'));
  };

  return (
    <>
    {isLoad ?
      <Box sx={{pr: {xs: 1, md: 2}, pl: {xs:1, md: 2}, pb: 2}}>
        {profileFormInfo.map((group, index) =>(
          <Box key={`pf_${index}`}>
          <Box sx={{
            borderLeft: "5px solid #D16960",
            textAlign: 'left',
            mt:2
          }}>
            <Typography sx={{fontWeight: 'bold', fontSize: 20, ml: 3}}>{group.title}</Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3}}>{group.message}</Typography>
            <Typography sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}>{group.warning}</Typography>
            {group.warnings && group.warnings.length > 0 ?
            group.warnings.map((w, ii) =>(
              <Typography key={`pf_l_${ii}`} sx={{fontWeight: 'bold', fontSize: 16, ml: 3, color: "#FF0000"}}>{w}</Typography>
            ))
            :<></>}
          </Box>
          <Box
            key={`pfb_${index}`}
            sx={{backgroundColor: '#F4F6F9', borderRadius: '0px 10px 10px 10px', pt: {xs: 2}, pb: 2, pr: 3, pl: 3, minWidth: {md: '520px'}}}
          >
            { 
              group.forms.map((pform, idx) =>(
                pform.fields.length > 1 && pform.id == 'horizontal' ?
                  <Grid container key={`pf_g_${idx}`}>
                    <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                      <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                        {pform.label}
                      </InputLabel>
                    </Grid>
                    {pform.fields.map((f) =>(
                      <Controller
                        key={f.id}
                        control={control}
                        name={f.id}
                        render={({ field, fieldState }) => (
                          <Grid item md={4.5} sm={6} xs={6}>
                            <TextField
                              {...field}
                              fullWidth
                              disabled
                              sx={{mt: {xs: 1, md: 2}}}
                              margin="normal"
                              color="secondary"
                              placeholder={f.placeholder}
                              error={fieldState.invalid}
                              helperText={fieldState.error?.message}
                            />
                          </Grid>
                        )}
                      />
                    ))}
                  </Grid>
                : pform.fields.length > 1 && pform.id == 'vertical' &&( (pform.label == '事業所住所' && kindState == '個人事業主') || (pform.label == '会社住所' && kindState == '法人') || pform.label == '代表者住所')?
                  <Box sx={{mb: {xs: 1}}} key={`pf_b_${idx}`}>
                    {pform.fields.map((f, i) =>(
                        <Grid container key={`pf_b_${idx}_${i}`}>
                          <Grid item md={3} sm={12} xs={12}sx={{m: 'auto'}}>
                            <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {i == 0 ? pform.label : ''}
                            </InputLabel>
                          </Grid>
                          <Controller
                            key={f.id}
                            control={control}
                            name={f.id}
                            render={({ field, fieldState }) => (
                              <Grid item md={9} sm={12} xs={12}>
                                <TextField
                                  {...field}
                                  fullWidth
                                  disabled
                                  sx={{mt: {xs: i == 0 ? 1 : 0, md: 1, sm: 1}}}
                                  color="secondary"
                                  placeholder={f.placeholder}
                                  error={fieldState.invalid}
                                  helperText={fieldState.error?.message}
                                />
                              </Grid>
                            )}
                          />
                        </Grid>
                      ))}
                  </Box>
                : pform.fields.length == 1 && (pform.fields[0].type == 'string' || pform.fields[0].type == 'searchText') && (kindState == '法人' || (kindState == '個人事業主' && pform.fields[0].id != 'corporatenumberAccount')) ?
                    <Controller
                      key={pform.fields[0].id}
                      control={control}
                      name={pform.fields[0].id}
                      disabled
                      render={({ field }) => (
                        <Grid container>
                          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                            <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {kindState == '個人事業主' && (pform.fields[0].id == 'company' || pform.fields[0].id == 'companynameFurigana') ? pform.label.replace('会社名', '屋号') : pform.label}
                            </InputLabel>
                          </Grid>
                          { pform.fields[0].id == 'accountnumber' || pform.fields[0].id == 'accounttypeOther' || pform.fields[0].id == 'emergencycontactPhoneno' || pform.fields[0].id == 'mobilephone' || pform.fields[0].id == 'position' ?
                          <>
                            <Grid item md={4.5} sm={6} xs={6}>
                              <TextField
                                {...field}
                                fullWidth
                                sx={{mt: {xs: 1, md: 2}}}
                                margin="normal"
                                color="secondary"
                              />
                            </Grid>
                            <Grid item md={4.5} sm={6} xs={6}></Grid>
                          </>
                          : pform.fields[0].id == 'identification'?
                          <>
                            <Grid item md={4.5} sm={6} xs={6}>
                              <TextField
                                {...field}
                                fullWidth
                                sx={{mt: {xs: 1, md: 2}, 'fieldset': {border: 'none'}}}
                                margin="normal"
                                color="secondary"
                              />
                            </Grid>
                            <Grid item md={4.5} sm={6} xs={6}></Grid>
                          </>
                          :
                          <Grid item md={9} sm={12} xs={12}>
                            <TextField
                              {...field}
                              fullWidth
                              sx={{mt: {xs: 1, md: 2}}}
                              margin="normal"
                              color="secondary"
                            />
                          </Grid>
                          }
                        </Grid>
                      )}
                    />
                : pform.fields.length == 1 && (pform.fields[0].type == 'select') ?
                    <Controller
                      key={pform.fields[0].id}
                      control={control}
                      name={pform.fields[0].id}
                      disabled
                      render={({ field }) => (
                        <Grid container>
                          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                            <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {pform.label}
                            </InputLabel>
                          </Grid>
                          <Grid item md={4.5} sm={6} xs={6}>
                            <FormControl fullWidth margin='normal' sx={{mt: {xs: 1, md: 2}}}>
                              <Select
                                labelId='kind_select'
                                {...field}
                                color="secondary"
                              >
                                <MenuItem value='' sx={{color:'gray'}}>選択してください</MenuItem>
                                {pform.fields[0].options.map((opt) =>(
                                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item md={4.5} sm={6} xs={6}></Grid>
                        </Grid>
                      )}
                    />
                : pform.fields.length == 1 && (pform.fields[0].type == 'hidden') ?
                    <Controller
                      key={pform.fields[0].id}
                      control={control}
                      name={pform.fields[0].id}
                      render={({ field }) => ( <Hidden/>)}
                    />
                : pform.fields.length == 1 && (pform.fields[0].type == 'calendar') ?
                    <Controller
                      key={pform.fields[0].id}
                      control={control}
                      name={pform.fields[0].id}
                      disabled
                      render={({ field }) => (
                        <Grid container>
                          <Grid item md={3} sm={12} xs = {12}sx={{m: 'auto'}}>
                            <InputLabel sx={{textAlign: 'left', pr: 2, whiteSpace: 'break-spaces'}}>
                              {pform.label}
                            </InputLabel>
                          </Grid>
                          <Grid item md={4.5} sm={12} xs={12}>
                            <FormControl margin='normal' fullWidth sx={{mt: {xs: 1, md: 2}}}>
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ja'>
                                <DatePicker
                                  {...field}
                                  format={'YYYY/MM/DD'}
                                  onChange={(newValue) =>{if(newValue) setValue(pform.fields[0].id, newValue)}}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>
                          <Grid item md={4.5} sm={0} xs={0}></Grid>
                        </Grid>
                      )}
                    />
                : 
                  <Box key={`pf_n_${idx}`}></Box>
              ))
            }
          </Box>
          </Box>
        ))}
        {isAccountDisable ? <></> :
        <Box sx={{position: 'sticky', bottom: 3, width: '100%', left: {xs: 0, md: '15vh'}, mt: 2}}>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>{onClickEdit()}}
            sx={{borderRadius: 5, width: {md: '30%', xs: '100%'}, fontWeight: 'bold', mt: {xs: 0, md: 2}, mb: {xs: 1, md: 2}}}
          >
            編集
          </Button>
        </Box>
        }
      </Box>
      :
      <></>
    }
    <ErrorModal message={errorMessage} modalOpen={errorModalOpen}></ErrorModal>
    </>
  );
}